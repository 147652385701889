import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/services"
import BgImage from "../../assets/images/2017/09/pipe-stack-slide.jpg"

const IndexPage = () => {
  const title = "Services"
  const content = "Induction pipe bending and much more"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default IndexPage
